interface Step {
    element: string;
    title: string;
    intro?: string;
    position?: string;
}

const STEPS: { [key: number]: Step[] } = {
    1: [
        {
            element: '[data-testId="page-title"]',
            title: 'immersive-help.dashboard-title',
            intro: 'immersive-help.dashboard-message',
        },
        {
            element: '[data-tutorial="stepTasks"]',
            title: 'immersive-help.tasks-title',
            intro: 'immersive-help.tasks-message',
        },
        {
            element: '[data-tutorial="stepExercises"]',
            title: 'immersive-help.exercises-title',
            intro: 'immersive-help.exercises-message',
        },
    ],
    2: [
        {
            element: '[data-testid="page-title"]',
            title: 'immersive-help.study-title',
            intro: 'immersive-help.study-message',
        },
        {
            element: '[data-testid="reminder-block"]',
            title: 'immersive-help.daily-reminder-title',
            intro: 'immersive-help.daily-reminder-message',
        },
        {
            element: '[data-testid="active-videos"]',
            title: 'immersive-help.active-videos-title',
            intro: 'immersive-help.active-videos-message',
        },
        {
            element: '[data-testid="past-meetings"]',
            title: 'immersive-help.past-online-meetings-title',
            intro: 'immersive-help.past-online-meetings-message',
        },
        {
            element: '[data-testid="basic-videos"]',
            title: 'immersive-help.basic-videos-title',
            intro: 'immersive-help.basic-videos-message',
        },
        {
            element: '[data-testid="documents"]',
            title: 'immersive-help.documents-title',
            intro: 'immersive-help.documents-message',
        },
        {
            element: '[data-testid="audio-book"]',
            title: 'immersive-help.audio-book-title',
            intro: 'immersive-help.audio-book-message',
        },
        {
            element: '[data-testid="magazines"]',
            title: 'immersive-help.magazines-title',
            intro: 'immersive-help.magazines-message',
        },
        {
            element: '[data-testid="other-themes"]',
            title: 'immersive-help.other-themes-title',
            intro: 'immersive-help.other-themes-message',
        },
    ],
    3: [
        {
            element: '[data-testId="page-title"]',
            title: 'immersive-help.meetings-title',
            intro: 'immersive-help.meetings-message',
        },
        {
            element: '[data-testId="upcoming-meetings-block"]',
            title: 'immersive-help.upcoming-meetings-title',
            intro: 'immersive-help.upcoming-meetings-message',
        },
        {
            element: '[data-testId="past-meetings-block"]',
            title: 'immersive-help.past-meetings-title',
            intro: 'immersive-help.past-meetings-message',
        },
    ],
    4: [
        {
            element: '[data-testId="page-title"]',
            title: 'immersive-help.events-title',
            intro: 'immersive-help.events-message',
        },
    ],
    5: [
        {
            element: '[data-testId="page-title"]',
            title: 'immersive-help.members-title',
            intro: 'immersive-help.members-message',
        },
    ],
    6: [
        {
            element: '[data-testId="pinned-items-link"]',
            title: 'immersive-help.pinned-items-title',
            intro: 'immersive-help.pinned-items-message',
            position: 'left'
        },
        {
            element: '[data-testId="my-documents-link"]',
            title: 'immersive-help.my-documents-title',
            intro: 'immersive-help.my-documents-message',
            position: 'left'
        },
        {
            element: '[data-testId="settings-personal-link"]',
            title: 'immersive-help.personal-settings-title',
            intro: 'immersive-help.personal-settings-message',
            position: 'left'
        },
        {
            element: '[data-testId="settings-billing-link"]',
            title: 'immersive-help.membership-title',
            intro: 'immersive-help.membership-message',
            position: 'left'
        },
        {
            element: '[data-testId="settings-company-link"]',
            title: 'immersive-help.members-title',
            intro: 'immersive-help.members-message',
            position: 'left'
        },
        {
            element: '[data-testId="settings-legal-link"]',
            title: 'immersive-help.info-title',
            intro: 'immersive-help.info-message',
            position: 'left'
        },
        {
            element: '#step-end',
            title: 'immersive-help.log-out-title',
            intro: 'immersive-help.log-out-message',
            position: 'left'
        },
    ],
};

interface UseOnboardingTutorialReturnType {
    immersiveHelp: (step: number) => Step[] | undefined;
}

export const getTutorialSteps = (step: number): Step[] => {
    return STEPS[step]
};

export const useOnboardingTutorial = (): UseOnboardingTutorialReturnType => {
    const getLocalStorageItem = <T>(key: string): T | null => {
        try {
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) as T : null;
        } catch (error) {
            console.error(`Error reading ${key} from localStorage:`, error);
            return null;
        }
    };

    const immersiveHelp = (step: number): Step[] | undefined => {
        const currentStep = getLocalStorageItem<number>("immersiveHelpStep") || 1;

        if (currentStep === step) {
            return getTutorialSteps(step);
        } else {
            return undefined
        }
    };

    return { immersiveHelp };
};