import { dayOfMonth, shortMonth } from "@/common/date.formats";
import { IEvent } from "@/types/models/events/event";
import { ILocation } from "@/types/models/events/location";
import { ISession } from "@/types/models/events/session";
import { Ref, ref } from "vue";

interface IUseEvent {
  sessions: Ref<Array<ISession>>;
  shortDays: Ref<string>;
  shortMonths: Ref<string>;
  startDate: Ref<string>;
  endDate: Ref<string>;
  location: Ref<ILocation | null>;
}
export const useEvent = (data: IEvent): IUseEvent => {
  const sessions = data?._embedded?.sessions ?? [];

  const startDateISO =
    sessions[0]?.startTimeStamp?.iso_date ??
    data.startTimestamp?.iso_date ??
    "";
  const endDateISO =
    sessions[sessions.length - 1]?.endTimeStamp?.iso_date ??
    data.endTimestamp?.iso_date ??
    "";

  const startDay = startDateISO ? dayOfMonth(startDateISO) : '';
  const endDay = endDateISO ? dayOfMonth(endDateISO) : '';

  const startMonth = startDateISO ? shortMonth(startDateISO) : '';
  const endMonth = endDateISO ? shortMonth(endDateISO) : '';

  const shortDays =
    startDay !== endDay ? `${startDay}-${endDay}` : `${startDay}`;
  const shortMonths =
    startMonth !== endMonth ? `${startMonth}-${endMonth}` : `${startMonth}`;
  return {
    sessions: ref(sessions),
    shortDays: ref(shortDays),
    shortMonths: ref(shortMonths),
    startDate: ref(startDateISO),
    endDate: ref(endDateISO),
    location: ref(data?._embedded?.location ?? null),
  };
};
