import { findItems } from "@/common/page.helpers";
import { IFilteredComponent } from "@/types/models/content/component";
import { computed, ComputedRef, Ref } from "vue";
import { usePage } from "@/composables/page";

interface IUseMyDocuments {
  page: Ref<IFilteredComponent | undefined>;
  loading: Ref<boolean>;
  error: Ref<null>;
  assignments: ComputedRef<IFilteredComponent[]>;
  sitdowns: ComputedRef<IFilteredComponent[]>;
}

export const useMyDocuments = (): IUseMyDocuments => {
  const { page, loading, error } = usePage("my-documents-page");

  const assignments = computed(() => 
    findItems(page.value, "assignment-block-items") || findItems(page.value, "a601e9a5-2444-4864-873a-1cd8769512f6") || []
  );

  const sitdowns = computed(() =>
    findItems(page.value, "sitdowns") ?? []
  );

  return { page, loading, error, assignments, sitdowns };
};
