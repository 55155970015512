import axios from "axios";
import { API_URL, APP_BRAND, APP_TYPE, APP_VERSION } from "@/common/config";

const instance = axios.create({
  baseURL: API_URL,
  timeout: 300000,
  headers: {
    "Content-Type": "application/json",
    "SLL-Brand": APP_BRAND,
    "Sll-App-Type": APP_TYPE,
    "Sll-App-Version": APP_VERSION,
    "Sll-App-Language": "NLD",
  },
});

export default instance;
