export const REGISTER_APP = "registerApp";
export const SET_AUTH = "setAuth";
export const SET_PERSON = "setPerson";
export const SET_MEMBERSHIPS = "setMemberships";
export const RESTORE_DEFAULT_STATE = "restoreDefaultState";
export const SET_LIVE_MEETINGS = "setLiveMeetings";
export const SET_MINIPLAY = "setMiniPlay";
export const SET_TOAST = "setToast";
export const ADD_TO_HISTORY = 'addToHistory';
export const CLEAN_HISTORY = 'cleanHistory';
