import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./common/fontawesome.library";
import setupInterceptors from "@/services/auth/setup.interceptors";
import tokenService from "./services/auth/token.service";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import accountService from "./services/auth/account.service";
import { i18n } from "./common/i18n.setup";
import VueClickAway from "vue3-click-away";
import { ADD_TO_HISTORY } from "./store/mutations.type";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.beforeEach((to, from, next) => {
    store.original.commit(ADD_TO_HISTORY, {
        path: to.path,
        params: to.params,
        query: to.query,
    });
    next();
});

tokenService.fillAuth();
accountService.fillActiveAccount();
setupInterceptors();

createApp(App)
    .use(i18n)
    .use(store.original)
    .use(router)
    .use(VueClickAway)
    .component("FontAwesomeIcon", FontAwesomeIcon)
    .mount("#app");
