import { componentService } from "@/services/services";
import { IFilteredComponent } from "@/types/models/content/component";
import { onMounted, Ref, ref } from "vue";

interface IUsePage {
  page: Ref<IFilteredComponent | undefined>;
  loading: Ref<boolean>;
  error: Ref<null>;
  refetchPage: () => void;
}

export const usePage = (name: string | number): IUsePage => {
  const page = ref<IFilteredComponent>();
  const loading = ref(false);
  const error = ref(null);

  const fetchPage = () => {
    loading.value = true;
    error.value = null;

    componentService
      .getFilteredPage(name, 'size=100')
      .then((c) => {
        page.value = c;
      })
      .catch((e) => {
        error.value = e;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  onMounted(() => {
    fetchPage();
  });

  return {
    page,
    loading,
    error,
    refetchPage: () => fetchPage(),
  };
};
