import Dashboard from "@/views/dashboard/Dashboard.vue";
import Onboarding from "@/components/popups/Onboarding.vue";
import DeclineTerms from "@/components/popups/DeclineTerms.vue";
import AssignmentDetails from "@/views/assignments/AssignmentDetails.vue";
import SitdownsOnboardingVue from "@/components/popups/SitdownsOnboarding.vue";
import SitdownToCompleteVue from "@/components/popups/StidownToComplete.vue";
import SitdownDetails from "@/views/sitdowns/SitdownDetails.vue";

const dashboardRoutes = [
  {
    path: "dashboard",
    name: "dashboard",
    components: {
      content: Dashboard,
    },
  },
  {
    path: "dashboard/onboarding",
    name: "onboarding",
    components: {
      content: Dashboard,
      modal: Onboarding,
    },
  },
  {
    path: "dashboard/onboarding/declined",
    name: "onboardingDeclined",
    components: {
      content: Dashboard,
      modal: DeclineTerms,
    },
  },
  {
    path: "dashboard/assignmentDetails/:contentId",
    name: "assignmentDetails",
    components: {
      content: AssignmentDetails,
    },
  },
  {
    path: "dashboard/sitdownDetails/:contentId",
    name: "sitdownDetails",
    components: {
      content: SitdownDetails,
    },
  },
  {
    path: "dashboard/sitdownsOnboarding/:videoId",
    name: "sitdownsOnboarding",
    components: {
      content: Dashboard,
      modal: SitdownsOnboardingVue,
    },
  },
  {
    path: "dashboard/sitdownToComplete",
    name: "sitdownToComplete",
    components: {
      content: Dashboard,
      modal: SitdownToCompleteVue,
    },
  },
];

export default dashboardRoutes;
