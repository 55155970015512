import { IComponent } from "@/types/models/content/component";
import { IFilteredComponent } from "@/types/models/content/component";
import { DataService } from "./base.service";

export class ComponentService extends DataService<
  IComponent,
  unknown,
  unknown
> {
  constructor() {
    super("content", "components");
  }

  async getFilteredPage(id: number | string, params?: string): Promise<IFilteredComponent> {
    return await this.get<IFilteredComponent>(id, `filtered=1${params ? `&${params}` : ''}`);
  }

  async getFilteredList(
    ids: string
  ): Promise<{ [key: number]: IFilteredComponent }> {
    return await this.get<{ [key: number]: IFilteredComponent }>(
      ids,
      `filtered=1`
    );
  }

  async pinItem(id: number | string, unpin = false): Promise<IComponent> {
    return await this.update(id, { pinnedByMe: !unpin });
  }
}
