import { utcToZonedTime, } from "date-fns-tz";
import { add, isPast } from "date-fns";

export const setExpireTime = (expiration: number): number => {
  const date = new Date();
  const dateInMlSeconds = date.getTime();
  const addMlSeconds = (expiration - 300) * 1000;
  return dateInMlSeconds + addMlSeconds;
};

export const compareDates = (milisec: number): boolean =>
  new Date().getTime() >= new Date(milisec).getTime();

export const checkDateForSitdownToComplete = (date: string, timezone: string): boolean => {
  const zonedDate = utcToZonedTime(new Date(date), timezone);
  const datetoCompare = add(zonedDate, { days: 30 });
  return isPast(datetoCompare);
};